import Views from "../../views";
import Controllers from "../controllers";

export const IsDeviceRegistration = () => {
    let params = new URLSearchParams(window.location.search);
    let hasRegister = params.get('register');
    return hasRegister ? true : false;
}

export const SwitchScreen = async (screen, params) => {
    let content = await Views[screen];
    document.body.innerHTML = content.default;
    document.title = 'Kiosk Reception' + ((screen == 'Default' || screen == 'Home') ? '' : ' | ' + screen);
    if (Controllers[screen] && typeof Controllers[screen] == 'function')
        Controllers[screen](document.body.firstChild, params);
}

export const HasSession = () => {
    return document.cookie.indexOf('ksk_d=') !== -1;
}

export const AddressModel = {
    "addressInfo": [
        {
            "address": {
                "type": "HOME",
                "primaryInd": true
            },
            "type": "Address"
        }
    ]
}

export const EmailModel = {
    "emailInfo": [
        {
            "email": {
                "primaryInd": true,
                "type": "EMAIL"
            }
        }
    ]
}

export const TelephoneModel = {
    "telephoneInfo": [
        {
            "telephone": {
                "phoneTechType": "PHONE",
                "phoneUseType": "HOME",
                "primaryInd": true
            },
            "type": "Communication"
        }
    ]
}