import { AddressModel, EmailModel, SwitchScreen, TelephoneModel } from '../utils/common';
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
    let [resource, config] = args;
    let response = await originalFetch(resource, config);
    if (resource.search("updateguestprofile") > 0 && response.status == 403) {
        SwitchScreen('InactiveProfile');
        return
    }
    if (response.status == 403) return location = '/';
    return response;
};


export const GetVenueDetails = () => {
    return fetch(process.env.API_ENDPOINT + 'venue-details')
        .then(res => res.json())
}

export const UpdateVerificationDetail = (data) => {
    return fetch(process.env.API_ENDPOINT + 'updateguestprofile', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: { 'Content-type': 'application/json' }
    }).then((res) => res.json())
}

export const GetNameTitles = () => {
    return fetch(process.env.API_ENDPOINT + 'name-titles')
        .then(res => res.json())
}

export const SearchCities = (key) => {
    let uri = encodeURI(process.env.API_ENDPOINT + 'city-lookup?searchKey=' + key)
    return fetch(uri)
        .then(res => res.json())
}

export const Register = (body) => {
    return fetch(process.env.API_ENDPOINT + 'register', {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
        headers: { 'Content-type': 'application/json' }
    }).then((res) => res.json());
}

export const PendingReservations = () => {
    return fetch(process.env.API_ENDPOINT + 'pending-reservation').then((res) => res.json());
}