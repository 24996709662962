import '../../assets/css/auto-complete.css';
import autoComplete from "../libs/auto-complete";
import { ObjectStore } from "../../glob-store";
import { GetNameTitles, UpdateVerificationDetail } from '../utils/service';
import { AddressModel, EmailModel, SwitchScreen, TelephoneModel } from '../utils/common';

/**
 * @param {HTMLElement} component
 * @param {any} props
 */

export default async (component, props) => {
    if (ObjectStore.venueLogo) component.querySelector('#venueLogo').setAttribute('src', ObjectStore.venueLogo);
    let { roomStay: { arrivalDate, departureDate }, reservationIdList, reservationGuests } = props.Data[0];
    let { addresses, customer, emails, telephones } = reservationGuests[0].profileInfo.profile;
    let profileId = reservationGuests[0].profileInfo.profileIdList[0].id;
    if (!addresses) addresses = { ...AddressModel };
    if (!emails) emails = { ...EmailModel };
    if (!telephones) telephones = { ...TelephoneModel };

    let address = addresses?.addressInfo[0]?.address || null;
    let email = emails?.emailInfo?.find(ei => ei.email.primaryInd) || null;
    let telephone = telephones?.telephoneInfo?.find(ti => ti.telephone.primaryInd) || null;
    let resv = reservationIdList.find(r => r.type == 'Reservation')
    let initialData = {};
    let updatedData = null;
    /**
     * Render the details on page
     */
    const SetDetail = () => {
        let infoItems = component.querySelectorAll('.booking-subtitle');
        for (let i = 0; i < infoItems.length; i++) {
            const item = infoItems[i];

            switch (item.id) {
                case 'guestName':
                    let title = customer.personName[0].nameTitle ? customer.personName[0].nameTitle + ' ' : '';
                    let givName = customer.personName[0].givenName ? customer.personName[0].givenName : '';
                    let surName = customer.personName[0].surname ? ' ' + customer.personName[0].surname : '';
                    item.innerText = title + givName + surName;
                    component.querySelector('#greetingLine').innerText = 'Welcome, ' + title + givName + surName;
                    break;
                case 'reservationId':
                    item.innerText = resv.id || '';
                    break;
                case 'addressLine':
                    if (address) {
                        let addrLn = address?.addressLine ? address.addressLine.filter(al => al) : [];
                        item.innerText = addrLn.join(', ')
                    }
                    break;
                case 'city':
                    item.innerText = address?.cityName ? address.cityName : '';
                    break;
                case 'postalCode':
                    item.innerText = address?.postalCode ? address.postalCode : ''
                    break;
                case 'birthDate':
                    item.innerText = customer.birthDate || ''
                    break;
                case 'emailAddress':
                    if (email) item.innerText = email.email.emailAddress || '';
                    break;
                case 'phoneNumber':
                    if (telephone) item.innerText = telephone.telephone.phoneNumber || '';
                    break;
                case 'checkInDate':
                    item.innerText = arrivalDate || '';
                    break;
                case 'checkoutDate':
                    item.innerText = departureDate || '';
                    break;
            }
        }
    }

    const SetTitles = async () => {
        let { Data } = await GetNameTitles();
        let options = Data.map(d => d.name);
        let sl = component.querySelector('#Salutation');
        let opts = ''
        for (let n = 0; n < options.length; n++) {
            const option = options[n];
            opts += `<option value="${option}" ${n == 0 ? 'selected' : ''}>${option}</option>`
        }
        sl.innerHTML = opts;
    }

    SetDetail()
    SetTitles()

    let cityAutocomplete = null;
    const SetModalData = () => {
        let adrs = address.addressLine ? address.addressLine.filter(al => al) : [];
        adrs = adrs.length ? adrs.join(', ') : '';

        initialData = {
            Salutation: customer.personName[0]?.nameTitle || null,
            FirstName: customer.personName[0]?.givenName || '',
            LastName: customer.personName[0]?.surname || '',
            Address: adrs || '',
            City: address?.cityName || '',
            State: address?.state || '',
            Country: address?.country?.code || '',
            Postcode: address?.postalCode || '',
            PhoneNumber: telephone?.telephone?.phoneNumber || '',
            Email: email?.email?.emailAddress || '',
            BirthDate: customer.birthDate || ''
        };

        let today = new Date(), dd = today.getDate(), mm = today.getMonth() + 1, yyyy = today.getFullYear() - 18;
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        today = yyyy + '-' + mm + '-' + dd;
        component.querySelector("#BirthDate").setAttribute("max", today);

        let fields = component.querySelectorAll('#verificationForm .form-control');
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            field.value = initialData[field.name]
        }
        document.getElementById('City').addEventListener("input", async (e) => {
            cityAutocomplete = false;
        });
        autoComplete(component.querySelector('#City'), (option) => {
            component.querySelector('#Country').value = option.CountryID;
            component.querySelector('#State').value = option.State;
            cityAutocomplete = true;
        });
    }

    component.querySelector('#editDetail').addEventListener('click', (event) => {
        $('#editModal').modal('show');
        SetModalData()
    })

    const ClearForm = () => {
        component.querySelector('#verificationForm').reset();
        component.querySelector('#verificationForm').classList.remove('was-validated')
    }

    component.querySelector('#cancelEdit').addEventListener('click', ClearForm)
    component.querySelector('#closeModal').addEventListener('click', ClearForm)

    const FieldsToUpdate = (fd) => {
        let fields = [];
        for (const key in initialData) {
            if (Object.hasOwnProperty.call(initialData, key)) {
                let value = initialData[key];
                if (value == null || value == '') value = '';
                if (value !== fd[key]) fields.push(key);
            }
        }
        return fields;
    }

    const BuildData = (fd) => {
        let u = { customer, emails, addresses, telephones };
        let blocks = []
        let fieldsToUpdate = FieldsToUpdate(fd);
        let retObj = {};
        for (let i = 0; i < fieldsToUpdate.length; i++) {
            const field = fieldsToUpdate[i];
            switch (field) {
                case 'Salutation':
                case 'FirstName':
                case 'LastName':
                case 'BirthDate':
                    u.customer.personName[0].nameTitle = fd.Salutation;
                    u.customer.personName[0].givenName = fd.FirstName;
                    u.customer.personName[0].surname = fd.LastName;
                    if (fd.BirthDate && fd.BirthDate !== "") {
                        u.customer.birthDate = fd.BirthDate;
                    } else {
                        delete fd.BirthDate;
                        delete u.customer.birthDate;
                    }
                    if (u.customer.personName.length > 1) u.customer.personName = [u.customer.personName[0]];
                    blocks.push('customer');
                    break;
                case 'Address':
                case 'City':
                case 'Country':
                case 'Postcode':
                    let pInd = u.addresses.addressInfo.findIndex((item) => item.address.primaryInd);
                    u.addresses.addressInfo[pInd].address.addressLine = fd.Address.split(', ');
                    u.addresses.addressInfo[pInd].address.cityName = fd.City;
                    u.addresses.addressInfo[pInd].address.country = { value: fd.Country };
                    u.addresses.addressInfo[pInd].address.state = fd.State;
                    u.addresses.addressInfo[pInd].address.postalCode = fd.Postcode;
                    if (u.addresses.addressInfo.length > 1) u.addresses.addressInfo = [u.addresses.addressInfo[pInd]];
                    blocks.push('addresses');
                    break;
                case 'PhoneNumber':
                    let ind = u.telephones.telephoneInfo.findIndex((item) => item.telephone.primaryInd);
                    u.telephones.telephoneInfo[ind].telephone.phoneNumber = fd.PhoneNumber;
                    if (u.telephones.telephoneInfo.length > 1) u.telephones.telephoneInfo = [u.telephones.telephoneInfo[ind]];
                    blocks.push('telephones');
                    break;
                case 'Email':
                    let ind1 = u.emails.emailInfo.findIndex((item) => item.email.primaryInd);
                    u.emails.emailInfo[ind1].email.emailAddress = fd.Email;
                    if (u.emails.emailInfo.length > 1) u.emails.emailInfo = [u.emails.emailInfo[ind1]];
                    blocks.push('emails');
                    break;
            }
            retObj = { ...u };
            Object.keys(u).forEach(key => {
                if (!blocks.includes(key)) delete retObj[key];
            });
        }
        return retObj;
    }

    const SubmitData = async (ud, fromForm) => {
        let btn = component.querySelector(fromForm ? '#submitBtn' : '#verifyRegistration');
        btn.innerHTML = '<div class="spinner-border text-primary" role="status"></div>';
        btn.setAttribute('disabled', true);
        try {
            let updateObj = ud ? {
                profileDetails: { ...ud, registeredProperty: props.Data[0].hotelId },
                profileIdList: [
                    {
                        "type": "Profile",
                        "id": profileId
                    }
                ]
            } : {};
            if (!ud && fromForm) {
                $('#editModal').modal('hide');
                return ClearForm();
            }
            let res = await UpdateVerificationDetail({
                profiledetailsObj: updateObj,
                profile_id: profileId,
                reservationId: resv.id
            });
            if (res.status == 'updated_profile' || res.status == 'alreadycorrect_info') {
                SwitchScreen('ThankYou', fromForm ? true : false);
                setTimeout(() => {
                    SwitchScreen('Home');
                }, 10000);
            } else {
                btn.innerHTML = 'CONFIRM';
                btn.removeAttribute('disabled');
                console.log(res);
            }
            ObjectStore.isVerifying = false;
            $('#editModal').modal('hide');
            ClearForm();
        } catch (error) {
            btn.innerHTML = fromForm ? 'UPDATE' : 'CONFIRM';
            btn.removeAttribute('disabled')
            console.log(error);
        }
    }


    component
        .querySelector('#verificationForm')
        .addEventListener('submit', async (event) => {
            try {
                event.preventDefault()
                event.stopPropagation()
                event.target.classList.add('was-validated');
                if (cityAutocomplete === false) {
                    document.getElementById("City").value = "";
                }
                if (event.target.checkValidity()) {
                    const data = Object.fromEntries(new FormData(event.target).entries());
                    let body = BuildData(data);
                    if (!Object.keys(body).length) updatedData = null;
                    updatedData = body;
                    SubmitData(updatedData, true)
                }
            } catch (error) {
                console.log(error);
            }
        })
    component.querySelector('#verifyRegistration').addEventListener('click', () => SubmitData())
}

