// import HomeView from './views/home.html';
import { RegisterSW } from './sw-register'
// import './js/controllers/home'
import './assets/css/style.css'
import { SwitchScreen, IsDeviceRegistration, HasSession } from './js/utils/common';

/**
 * Register service worker for PWA
 */
RegisterSW();
/**
 * Load Initial Page
 */
let screen = 'Default', hasSession = HasSession();
if (hasSession) screen = 'Home';
if (hasSession && IsDeviceRegistration()){
    alert("A reception profile is already registered with device"); 
    location = '/';
}
if (!hasSession && IsDeviceRegistration()) screen = 'Setup';
SwitchScreen(screen)